<template>
  <div class="footer-wrapper" id="footer-wrapper"
       :class="[{hide: $route.name === 'quizHub' || $route.name === 'quizGame'|| $route.name === 'stage1'|| $route.name === 'stage2'}]">
    <div class="footer-item">

      <div class="left-block">
        <div class="footer-wrapper__links footer-links">
          <a
            :onclick="`gtag('event', 'click', {  'event_category': 'кнопка Про Тева',  'event_label': '${$route.name}'})`"
            class="footer-links__link"
            href="https://www.teva.ua/About-Teva/"
            target="_blank"
          >
              Про Тева
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5415 4.32129L4.5415 12.3213" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.875 4.32129H12.5417V10.988" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
          <a
            :onclick="`gtag('event', 'click', {  'event_category': 'кнопка Пацiєнтам',  'event_label': '${$route.name}'})`"
            class="footer-links__link"
            href="https://liky.teva.ua/"
            target="_blank"
          >
              Пацiєнтам
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5415 4.32129L4.5415 12.3213" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.875 4.32129H12.5417V10.988" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>

        <p class="text1" v-html="textFooter.footer_one"></p>
      </div>

      <div class="center-block">

        <p class="left-text" v-html="textFooter.footer_two"></p>

        <a id="sub-btn"
          :onclick="`gtag('event', 'click', {  'event_category': 'кнопка подписаться',  'event_label': '${$route.name}'})`"
           target="_blank" href="https://consent.teva.ua/" class="sub-btn hover_type1">
          <p style="pointer-events: none;">Пiдписатися</p>
        </a>

      </div>

      <div class="right-block">
        <p class="text2" v-html="textFooter.footer_third"></p>
      </div>

      <p class="text1" v-html="textFooter.footer_one"></p>

    </div>

  </div>
</template>

<script>
import axios from "axios"
import {mapActions} from "vuex";

export default {
  name: "footer-wrapper",

  data: () => ({

    apiUrl: "",

    currentLang: null,

    textFooter: {},

    activeClass: "active"

  }),
  beforeMount() {
    this.getFooterText();
  },
  computed: {},
  methods: {
    ...mapActions([
      'doHeightContent',
    ]),
    getFooterText() {
      axios.get(this.$root.globalUrl + "/api/getFooterText?locale=ukr").then((res) => {
        this.textFooter = JSON.parse(res.data)
      }).then(() => {
        this.$nextTick(() => {
          this.doHeightContent();
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.footer-wrapper {
  margin: auto 0;
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-self: flex-end;
  background: $green;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  min-height: 150px;

  &.hide {
    display: none;
  }

  sup {
    line-height: 0 !important;
    font-size: 80%;
    vertical-align: revert;
  }

  .footer-item {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 42px 0;
    }

    @media screen and (max-width: 425px) {
      align-items: center;
    }

    .left-block {
      @media screen and (max-width: 1000px) {
        margin-bottom: 10px;
      }

      .text1 {
        display: block;

        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
    }

    .text1 {
      max-width: 345px;
      width: 100%;
      color: $white;
      font-size: 10px;
      font-family: $R;
      order: 3;
      display: none;

      @media screen and (max-width: 1000px) {
        display: block;
        max-width: 1000px;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 425px) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    .center-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px;
      @media screen and (max-width: 1000px) {
        margin: 0 0 10px 0;
        order: 1;
      }
      @media screen and (max-width: 425px) {
        margin: 0;
        flex-direction: column;
      }


      .left-text {
        color: $white;
        font-family: $B;
        font-size: 14px;
        margin-right: 22px;
        max-width: 250px;
        width: 100%;

        @media screen and (max-width: 1000px) {
          margin-right: 15px;
        }

        @media screen and (max-width: 425px) {
          font-size: 14px;
          text-align: center;
          max-width: 300px;
          width: 100%;
          margin-bottom: 13px;
          margin-right: 0;
        }
      }

      a {
        text-decoration: none;
      }

      .sub-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 35px;
        width: 190px;
        min-width: 140px;
        height: 50px;

        @media screen and (max-width: 1000px) {
          width: 140px;
          height: 30px;
        }

        @media screen and (max-width: 425px) {
          width: 192px;
          height: 50px;
          margin-bottom: 40px;
        }

        p {
          font-size: 16px;
          color: $green;
          font-family: $B;

          @media screen and (max-width: 1000px) {
            font-size: 14px;
          }

          @media screen and (max-width: 425px) {
            font-size: 14px;
          }
        }
      }
    }

    .right-block {
      @media screen and (max-width: 1000px) {
        order: 2;
        margin: 0 0 10px 0;
      }

      .text2 {
        max-width: 292px;
        width: 100%;
        color: $white;
        font-size: 12px;
        font-family: $M;

        a {
          color: $white;
          @media screen and (max-width: 425px) {
            display: inline;
          }
        }

        @media screen and (max-width: 425px) {
          font-size: 12px;
          text-align: center;
          margin-bottom: 20px;
  
        }
      }
    }

  }

  &__links {
    margin-bottom: 4px;
  }
}

.footer-links {
  display: flex;
  gap: 32px;

  @media screen and (max-width: 425px) {
    justify-content: center;
    margin-bottom: 32px;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: "Commissioner-Regular";
    color: $white;
    font-size: 16px;
    white-space: nowrap;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: $lime;

      path {
        stroke: $lime;
      }
    }

    & svg {
      display: block;
      width: 16px;
      height: 16px;
    }

    path {
      transition: stroke 0.3s;
    }
  }
}
</style>
